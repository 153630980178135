<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
              <div class="col-md-4 col-12">
                <div class="mb-1">
                  <label class="form-label" for="product_id">Product Type</label>
                  <v-select
                    placeholder="Select Product/Service Type"
                    v-model="productType"
                    :options="productTypes"
                    label="text"
                    :reduce="text => text.id"
                  />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="mb-1">
                  <label class="form-label" for="product_id">Product</label>
                  <AsyncSelect
                      placeholder="Select Product/Service"
                      :api-service="fetchProductList"
                      v-model="selectedProduct"
                      label="text"
                      :additional-query="productQueryObj"
                      :format-label="option => option.text"
                      ref="productSelector"
                  />
                </div>
              </div>

              <div class="col-md-2 col-12">
                <div class="mb-1">
                  <label class="form-label" for="quantity">Unit</label>
                  <input
                    :value="unit"
                    name="Unit"
                    type="text"
                    class="form-control text-right"
                    readonly
                  />
                </div>
              </div>
              <div class="col-md-2 col-12" v-if="alternativeUnits.length > 0">
                <div class="mb-1">
                  <label class="form-label" for="product_id">Alternative Unit</label>
                  <vField
                    v-model="data.alt_unit_id"
                    name="alt_unit_id"
                    type="text"
                    class="form-control d-none"
                  />
                  <v-select
                    placeholder="Select alternative unit"
                    v-model="data.alt_unit_id"
                    :options="alternativeUnits"
                    label="name"
                    :reduce="name => name.id"
                  />
                </div>
              </div>
              <div class="col-md-2 col-12" v-if="type === 'from_po'">
                <div class="mb-1">
                  <label class="form-label" for="quantity">PO Quantity</label>
                  <input
                      v-model="data.po_quantity"
                      name="PO Quantity"
                      type="text"
                      class="form-control text-right"
                      readonly
                  />
                </div>
              </div>
              <div class="col-md-2 col-12" v-if="type === 'from_po'">
                <div class="mb-1">
                  <label class="form-label" for="quantity">Already Received</label>
                  <input
                      v-model="data.total_received"
                      name="Already Received"
                      type="text"
                      class="form-control text-right"
                      readonly
                  />
                </div>
              </div>

              <div class="col-md-2 col-12" v-if="alternativeUnits.length > 0">
                <div class="mb-1">
                  <label class="form-label" for="quantity">Alt Unit Quantity</label>
                  <input
                      v-model="data.alt_unit_qty"
                      name="Received"
                      type="number"
                      class="form-control text-right"
                  />
                </div>
              </div>

              <div class="col-md-2 col-12">
                <div class="mb-1">
                  <label class="form-label" for="quantity">Receive Quantity</label>
                  <input
                      v-model="data.quantity"
                      name="Received"
                      type="number"
                      class="form-control text-right"
                  />
                </div>
              </div>

              <div class="col-md-2 col-12">
                <div class="mb-1">
                  <label class="form-label" for="rate">Rate</label>
                  <input
                      v-model="data.rate"
                      name="rate"
                      type="number"
                      class="form-control text-right"
                  />
                </div>
              </div>

              <div class="col-md-2 col-12">
                <div class="mb-1">
                  <label class="form-label" for="rate">Amount</label>
                  <input
                      v-model="amount"
                      name="rate"
                      type="number"
                      class="form-control text-right"
                  />
                </div>
              </div>

                <div class="col-md-3 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                        <textarea
                            rows="1"
                            style="height: 38px"
                            v-model="data.description"
                            class="form-control"
                        />
                    </div>
                </div>
                <div role="button" v-if="data.id" @click="goToBarcodePage(data)" class="col-md-1">
                    <button class="btn btn-secondary btn-sm mt-2"
                            style="cursor: pointer; margin-left: 10px"
                    >
                        <i class="fas fa-print"></i>
                    </button>

                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer font-medium-3" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,computed } from "@vue/runtime-core"
import {onMounted, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import handleInventory from "@/services/modules/inventory";
import handlePurchaseAndSales from "@/services/modules/purchase";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
const route  = useRoute();
const router = useRouter();

let unit = ref('');
const pr = defineProps({
  title: String,
  type: String,
  data: Object,
  index: Number,
  productTypes: Array,
})
const {fetchProductAlternativeUnits} = handleInventory()
const {fetchProductList, fetchProductStock} = handlePurchaseAndSales()
const alternativeUnits = ref([]);
const productType = ref(null);
const productSelector = ref(null);
const selectedProduct = ref(null);

const productId = computed(() => pr.data.product_id)
const altQuantity = computed(() => pr.data.alt_unit_qty)
const altUnit = computed(() => pr.data.alt_unit_id)
const amount = computed(() => pr.data.quantity * pr.data.rate)
const productQueryObj = computed(() => productType.value ? {product_type: productType.value} : null)
let query = `?company_id=${route.params.companyId}&skip_stock_check=1`;

const getUnit = () => {
  if (productId.value) {
    fetchProductStock(productId.value, query).then(res => {
      if(res.status) {
        unit.value = res.data.unit;
        return;
      }
    });
  }
  unit.value = '';
}

const goToBarcodePage = (item) => {
    let param = {
        companyId: route.params.companyId,
        moduleId: route.params.moduleId,
        menuId: route.params.menuId,
        pageId: route.params.pageId
    }

    let query = route.query;
    query.grnItemId = item.id;

    router.push({
        name: 'grn-barcode-print',
        params: param,
        query: query
    })
}

watch(selectedProduct, (newValue) => {
  pr.data.product_id = newValue ? newValue.id : null;
})

watch(productType, () => {
  productSelector.value.clear();
  pr.data.product_id = null;
  unit.value = '';
})

watch(altQuantity, (newValue, oldValue) => {
  //to ignore the calculation set quantity during on page load during edit
  if(route.query.grnId && oldValue === null) {
    return;
  }

  if(newValue && pr.data.alt_unit_id) {
    const altUnit = alternativeUnits.value.find(altUnit => altUnit.id === pr.data.alt_unit_id)
    pr.data.quantity = newValue * altUnit.conversion_rate;
  }
})

watch(altUnit, (newValue, oldValue) => {
  //to ignore the calculation set quantity during on page load during edit
  if(route.query.grnId && oldValue === null) {
    return;
  }

  if(newValue && pr.data.alt_unit_qty) {
    const altUnit = alternativeUnits.value.find(altUnit => altUnit.id === newValue)
    pr.data.quantity = pr.data.alt_unit_qty * altUnit.conversion_rate;
  }
})

watch(productId, (newValue) => {
  getUnit()
  if(newValue) {
    fetchProductAlternativeUnits(newValue, `?company_id=${route.params.companyId}`).then(res => {
      alternativeUnits.value = res.data;
    })
  }
})

onMounted(() => {
  if(productId.value) {
    getUnit()
    fetchProductAlternativeUnits(productId.value, `?company_id=${route.params.companyId}`).then(res => {
      alternativeUnits.value = res.data;
    })
    selectedProduct.value = {
      id: productId.value,
      text: pr.data.product.name
    };
  }
})
</script>


<style scoped>
.journal-line{
    border: 1px solid #f0f2f5;
}
.journal-line:hover{
    background: #f0f2f5;
}
</style>

